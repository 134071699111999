import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import resourcesIcon from '../../assets/img/resourcesIcon.svg';
import ChevronRight from '../Svg/ChevronRight';
import UserGroupIcon from '../Svg/UserGroupIcon';
import CustomMain from './CustomMain';
import MainNav from './MainNav';
import visitorICon from '../../assets/img/visitorICon.svg';

import returnIcon from '../../assets/img/returnIcon.svg';

import reportsIcon from '../../assets/img/reportsIcon.svg';

import statIcon from '../../assets/img/statIcon.svg';

export default function ImmigrationRoutes(props) {
  const { sidebarToggle, title } = props;
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const returnsRoutes = [
    { name: 'Visitor Status', to: '/formQuestionOptionsTypes/1/Visitor Status' },
    { name: 'Registeration Type', to: '/formQuestionOptionsTypes/1013/Registeration Type' },
    { name: 'Users Role', to: '/roles' },
    { name: 'Governorates', to: '/formQuestionOptionsTypes/1011/Governorates' },
    { name: 'Areas', to: '/formQuestionOptionsTypes/1012/Areas' },
    // { name: 'Languages', to: '/language' },
    { name: 'Occupation Category', to: '/formQuestionOptionsTypes/1014/Occupation Category' },
    { name: 'Occupations', to: '/formQuestionOptionsTypes/1015/Occupations' },
    { name: 'Employment Status', to: '/formQuestionOptionsTypes/1016/Employment Status' },
    { name: 'Levels Education', to: '/formQuestionOptionsTypes/1017/Levels Education' },
    { name: 'Actions', to: '/formQuestionOptionsTypes/1018/Actions' },
    { name: 'Age Group', to: '/formQuestionOptionsTypes/1006/Age Group' },
    { name: 'Martial Status', to: '/formQuestionOptionsTypes/1019/Martial Status' },
    { name: 'Registation Method', to: '/formQuestionOptionsTypes/1009/Registation Method' },
    { name: 'Visit Reasons', to: '/formQuestionOptionsTypes/1008/Visit Reasons' },
  ];
  const reportsRoutes = [
    { name: 'Visitors', to: '/reports/visitors' },
    { name: 'Registeration', to: '/reports' },
    // { name: 'Users', to: '/roles' },
  ];

  return (
    <div className={`${isOpen ? 'bg-[#222222]' : ''} `}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`${
          isOpen ? 'bg-[#222222]' : ''
        } flex justify-between items-center  w-full duration-200 mt-4 py-2 px-6  hover:bg-[#222222]  hover:text-[#fdce00]`}
      >
        {/* {nav.icon} */}
        <div className={` flex items-center`}>
          <img alt="x" src={resourcesIcon} />
          {sidebarToggle && (
            <>
              <span className="mx-4">
                <div className="flex items-center space-x-2">
                  <b>{t(title)}</b>
                </div>
              </span>
            </>
          )}
        </div>

        <ChevronRight className={`w-5 h-5 transform ${isOpen ? '-rotate-90' : 'rotate-90'}`} />
      </button>
      {isOpen && (
        <>
          <MainNav
            typeId={1}
            purposeOfRequest={'1105'}
            title={t('Visitors')}
            icon={visitorICon}
            sidebarToggle={sidebarToggle}
          />
          {/* <MainNav typeId={1} title={t('Registeration')} icon={returnIcon} sidebarToggle={sidebarToggle} /> */}

          {/* <NavLink
            to="/users"
            activeClassName="bg-[#222222] text-white border-white"
            activeStyle={{
              borderColor: '#fff',
            }}
            className="flex items-center duration-200 mt-4 py-2 px-6 border-l-0  hover:bg-[#222222]  hover:text-[#fdce00]"
          >
            <UserGroupIcon />
            {sidebarToggle && <span className="mx-4">{t('Users')}</span>}
          </NavLink> */}
          {/* <NavLink
            to="/employees"
            activeClassName="bg-[#222222] text-white border-white"
            activeStyle={{
              borderColor: '#fff',
            }}
            className="flex items-center duration-200 mt-4 py-2 px-6 border-l-0  hover:bg-[#222222]  hover:text-[#fdce00]"
          >
            <UserGroupIcon />
            {sidebarToggle && <span className="mx-4">{t('Employees')}</span>}
          </NavLink> */}
          <CustomMain routes={reportsRoutes} title={t('Reports')} icon={reportsIcon} sidebarToggle={sidebarToggle} />
          {/* <CustomMain routes={returnsRoutes} title={t('Statistics')} icon={statIcon} sidebarToggle={sidebarToggle} /> */}
          {/* <NavLink
            to="/Calendar"
            activeClassName="bg-[#222222] text-white border-white"
            activeStyle={{
              borderColor: '#fff',
            }}
            className="flex items-center duration-200 mt-4 py-2 px-6 border-l-0  hover:bg-[#222222]  hover:text-[#fdce00]"
          >
            <img alt="x" src={calIIcon} />
            {sidebarToggle && <span className="mx-4">{t('Calendar')}</span>}
          </NavLink> */}
        </>
      )}
    </div>
  );
}
