import { useQuery, useQueryClient } from 'react-query';
import Modal from '../../../SharedComponents/Modal';
import { getRoles } from './ApiRoles';
import EditRole from './EditRole';
import AddRole from './AddRole';
import DeleteRole from './DeleteRole';
import Home from '../../../SharedComponents/Svg/Home';
import { useState } from 'react';
import RolePermission from './RolePermission';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BreadCrumbs from '../../../SharedComponents/BreadCrumbs';
import TableSearch from '../../../SharedComponents/TableSearch';
import { dir } from '../../../utils/localisation';

export default function Roles() {
  const { data } = useQuery(['roles'], getRoles, {
    staleTime: 9999999999,
  });
  const { t, i18n } = useTranslation();
  // to control how many rows to show in table

  const [entries, setEntries] = useState(10);
  const onChangeEntries = (e) => {
    setEntries(e.target.value);
  };
  return (
    <>
      <header className="flex justify-between">
        <BreadCrumbs to="/roles" name={t('Roles')} />

        {/* <div className="flex items-center bg-white rounded border shadow">
          <div className="pl-4 pr-12 py-4 relative overflow-hidden">
            <a href="/">
              <Home />
            </a>
            <div className="text-gray-100">
              <hr className="w-11 border text-gray-50 border-gray-50 absolute transform -rotate-45 right-0 bottom-0 mb-3" />
              <hr className="w-11 border text-gray-50 border-gray-50 absolute transform rotate-45 right-0 top-0 mt-3" />
            </div>
          </div>
          <div className="pl-4 pr-12 py-4 relative overflow-hidden">
            <a href="/dashboard">Dashboard</a>
            <div className="text-gray-100">
              <hr className="w-11 border text-gray-50 border-gray-50 absolute transform -rotate-45 right-0 bottom-0 mb-3" />
              <hr className="w-11 border text-gray-50 border-gray-50 absolute transform rotate-45 right-0 top-0 mt-3" />
            </div>
          </div>

          <div className="pl-4 pr-12 py-4">
            <Link to="roles">Roles</Link>
          </div>
        </div> */}
        <div>{/* <AddRole /> */}</div>
      </header>

      {/* Table */}
      <div className="bg-white rounded border shadow-md mt-6">
        <div className="pt-6 px-5">
          <div className="border-[#eaedf0] border-b flex   justify-between relative  ">
            <div className={` flex items-center space-x-3 pb-4 ${dir('', 'space-x-reverse')}`}>
              <h2 className="text-sm font-bold uppercase">{t('Users Role')}</h2>
              <h4 className="bg-move text-white py-1 px-3 rounded-md">{data?.data?.length}</h4>
            </div>

            <div className="flex transform  relative z-10 "></div>
          </div>
        </div>
        <div className=" px-5  mt-4">
          <div className=" flex   justify-between relative  ">
            <div className={` flex items-center space-x-3 pb-4 ${dir('', 'space-x-reverse')}`}>
              <AddRole />
            </div>

            <div className="flex transform  relative z-10 ">
              <TableSearch
                entries={entries}
                //   onChangeEntries={onChangeEntries}
                //   onSearch={onSearch}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className=" overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y-2 divide-gray-200">
                  <thead className=" text-sm font-bold text-black">
                    <tr>
                      <th
                        scope="col"
                        className={`px-6 py-3 ${i18n.language === 'en' ? 'text-left' : 'text-right'} uppercase `}
                      >
                        {t('Name')}
                      </th>
                      <th
                        scope="col"
                        className={`px-6 py-3 ${i18n.language === 'en' ? 'text-left' : 'text-right'} uppercase w-64 `}
                      >
                        {t('Options')}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200 relative text-base text-darkgray font-bold">
                    {data &&
                      data?.data?.map((role) => {
                        return (
                          <tr key={role.id} className="">
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className=" ">{role.name}</div>
                            </td>
                            <td
                              className={`px-6 py-4 whitespace-nowrap flex items-center ${
                                i18n.language === 'en' ? '' : 'space-x-reverse'
                              } space-x-2`}
                            >
                              <EditRole id={role.id} name={role.name} />
                              <RolePermission name={role.name} /> <DeleteRole id={role.id} />
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
