import axios from 'axios';
import React, { useEffect } from 'react';
import { useQuery } from 'react-query';

export const getLanguages = async () => {
  const query = await axios.post('/Language/GetAll');
  const data = query.data.data;
  const isDone = query.data.isDone;
  return { data, isDone };
};

export default function LanguageList(props) {
  const { languageId, setLanguageId, className, children } = props;
  const language = localStorage.getItem('lang');

  useEffect(() => {
    if (language === 'ar') {
      setLanguageId(1);
    } else if (language === 'en') {
      setLanguageId(2);
    }
    // console.log('language', language);
    // setLanguageId(languages.data?.data.sort((a, b) => (a.orderNum > b.orderNum ? 1 : -1))[0].id);
  }, [language]);

  const { data, isLoading, isError } = useQuery('languages', getLanguages, {
    staleTime: 360000000,
  });

  if (isLoading) return '';
  if (isError) return '';

  return (
    <>
      {data.data && (
        <div className={className ? className : 'flex'}>
          {data.data
            ?.sort((a, b) => (a.orderNum > b.orderNum ? 1 : -1))
            .map((language) => (
              <div
                key={language.id}
                className={`flex justify-center items-center space-x-2 ${
                  languageId === language.id ? 'text-pagetabhover border-pagetabhover' : ''
                }   hover:text-pagetabhover pb-4 px-4 z-50 border-b hover:border-pagetabhover transition duration-300 ease-in-out cursor-pointer `}
                onClick={(e) => setLanguageId(language.id)}
              >
                <span>{language.name}</span>
              </div>
            ))}
          {children}
        </div>
      )}
    </>
  );
}
