import axios from 'axios';

export const getFolders = async (e) => {
  const query = await axios.post('/AstFolder/GetAll', e.queryKey[1]);
  const data = query.data.data;
  const isDone = query.data.isDone;
  const paginationResult = query.data.paginationResult;
  return { data, isDone, paginationResult };
};
export const getFolder = async (e) => {
  const id = e.queryKey[1];
  const query = await axios.get(`/AstFolder/Get?id=${id}`);
  const data = query.data.data;
  const isDone = query.data.isDone;
  const paginationResult = query.data.paginationResult;
  return { data, isDone, paginationResult };
};

export const addFolder = async (e) => {
  const { data } = await axios.post('/AstFolder/Add', e);
  return data;
};
export const editFolder = async (e) => {
  const { data } = await axios.post('/AstFolder/Edit', e);
  return data;
};

export const deleteFolder = async (e) => {
  const { data } = await axios.post('/AstFolder/Delete', e);
  return data;
};

// Media Api

export const addAsset = async (e) => {
  const { data } = await axios.post('/AstAsset/Add', e);
  return data;
};
export const editAsset = async (e) => {
  const { data } = await axios.post('/AstAsset/Edit', e);
  return data;
};

export const deleteAsset = async (e) => {
  const { data } = await axios.post('/AstAsset/Delete', e);
  return data;
};

export const moveAsset = async (e) => {
  const { data } = await axios.post('/AstFolder/AddAssets', e);
  return data;
};

// Exta API
export const getLanguages = async () => {
  const query = await axios.post('/Language/GetAll');
  const data = query.data.data;
  const isDone = query.data.isDone;
  return { data, isDone };
};

// submit data to server
export const uploadMedia = async (formData) => {
  const mediaUpload = await axios.post('https://rate2020cdn.key-frame.cf/api/image', formData);
  // const data = query.data.data;
  // const isDone = query.data.isDone;
  return mediaUpload;
};

// const response = await axios.post(
//   'https://toufimages.key-frame.cf/api/image',
//   formData
// );
