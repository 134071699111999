import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ChevronRight from '../Svg/ChevronRight';
import SubNav from './SubNav';

export default function MainNav(props) {
  const { typeId, title, icon, sidebarToggle, purposeOfRequest } = props;
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  return (
    <div className={`${isOpen ? 'bg-[#222222]' : ''} `}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`${
          isOpen ? 'bg-[#222222]' : ''
        } flex justify-between items-center  w-full duration-200 mt-4 py-2 px-6  hover:bg-[#222222]  hover:text-[#fdce00]`}
      >
        {/* {nav.icon} */}
        <div className={` flex items-center`}>
          <img alt="x" src={icon} />
          {sidebarToggle && (
            <>
              <span className="mx-4">
                <div className="flex items-center space-x-2">
                  <b>{t(title)}</b>
                </div>
              </span>
            </>
          )}
        </div>

        <ChevronRight className={`w-5 h-5 transform ${isOpen ? '-rotate-90' : 'rotate-90'}`} />
      </button>
      {isOpen && <SubNav purposeOfRequest={purposeOfRequest} typeId={1} />}
    </div>
  );
}
