import bg from './assets/img/bg.png';
import logokey from './assets/img/logokey.svg';
import facebook from './assets/img/facebook.svg';
import google from './assets/img/google.svg';
import twitter from './assets/img/twitter.svg';
import LoadingButton from './SharedComponents/Svg/LoadingButton';
import { Formik, Field, Form } from 'formik';
import { useMutation, useQueryClient } from 'react-query';
import { Authenticate } from './Api/Authenticate';
import { useState } from 'react';
import { BrowserRouter, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Input from './SharedComponents/Input';

export default function Login({ setToken }) {
  
  const [logged, setLogged] = useState();
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  const { mutate, data, isLoading } = useMutation(Authenticate, {
    onSuccess: async (data) => {
      if (data.isDone) {
        setToken(data);
        setLogged(true);
        queryClient.invalidateQueries('currentUser');
      }
    },
  });
  const loginHandler = async (e) => {
    try {
      await mutate(e);
    } catch (error) {}
  };

  return (
    <>
      {logged ? (
        <BrowserRouter>
          <Redirect to="/" />
        </BrowserRouter>
      ) : (
        <div
          className="h-screen rellative flex items-center justify-center overflow-y-hidden"
          style={{ backgroundColor: '#f4f5fd' }}
        >
          <div className=" w-full absolute top-0 left-0 z-0 h-full">
            <img className="object-coverz  w-full h-screen" src={bg} alt={'bg'} />
          </div>
          <div className="bg-white z-10 border shadow-lg px-16 py-12 rounded">
            <div className="text-center">
              <img className="inline-block mb-8" src={logokey} alt={'LogoKey'} />
            </div>
            <b className="text-xl mb-8 block text-center">{t('Sign in')}</b>
            <Formik initialValues={{ userName: '', password: '' }} onSubmit={loginHandler}>
              <Form>
                {/* <Input label={t('Username or email')} name="userName" type="text" /> */}
                <Field
                  // style={{ textIndent: '10px' }}
                  placeholder={t('Username or email')}
                  className="border rounded  w-full mb-8 p-1"
                  name="userName"
                  type="text"
                />
                <Field
                  style={{ textIndent: '10px' }}
                  placeholder={t('Password')}
                  className="border rounded  w-full mb-8 p-1"
                  name="password"
                  type="password"
                />
                <div className="flex justify-between  mb-8">
                  <div>
                    <label className="font-bold text-gray-400 hover:text-gray-500">
                      <Field className={i18n.language === 'en' ? 'mr-2' : 'ml-2'} type="checkbox" name="remeberme" />
                      {t('Remeber me')}
                    </label>
                  </div>
                  <div>
                    <a className="font-bold text-gray-400 hover:text-gray-500" href="/">
                      {t('Reset password')}
                    </a>
                  </div>
                </div>

                <button
                  className="bg-darkblue hover:bg-darkbluehover transition duration-300  block rounded w-full text-white p-2 relative"
                  type="submit"
                >
                  {isLoading && <LoadingButton />}
                  {t('Sign in')}
                </button>
                {data?.isDone === false && (
                  <span className="border-red-400 border text-center block rounded mt-4 text-red-400 p-2">
                    {data?.message}
                  </span>
                )}
              </Form>
            </Formik>
            <hr className="block mt-8 mb-4" />
            <span className="text-sm text-gray-400 text-center block mb-4">
              {t('Login with your social media account.')}
            </span>
            <div className={`flex justify-center space-x-2 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}>
              <a
                href="/"
                className="flex items-center justify-center  bg-facebook hover:bg-facebookhover duration-300 transition rounded-full h-8 w-8"
              >
                <img src={facebook} alt="facebook" />
              </a>
              <a
                href="/"
                className="flex items-center justify-center  bg-twitter hover:bg-twitterhover duration-300 transition rounded-full h-8 w-8"
              >
                <img src={twitter} alt="twitter" />
              </a>
              <a
                href="/"
                className="flex items-center justify-center  bg-google hover:bg-googlehover duration-300 transition rounded-full h-8 w-8"
              >
                <img src={google} alt="google" />
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

// Login.propTypes = {
//   setToken: PropTypes.func.isRequired,
// };
